<template>
    <div>
        <section class="relative pb-8 leading-7 text-gray-900 bg-white border-solid">
            <div class="lg:pl-8 max-w-7xl box-border mx-auto border-solid">
                <div class="lg:flex-row flex flex-col items-center leading-7 text-gray-900 border-0 border-gray-200">
                    <div
                        class="lg:w-1/2 md:p-16 lg:p-0 lg:pl-10 lg:pr-20 box-border flex flex-col justify-center w-full h-full p-8 text-gray-900 border-solid"
                    >
                        <h2
                            class="sm:text-4xl md:text-5xl m-0 text-3xl font-medium leading-tight tracking-tight text-left text-black"
                        >
                            How to add liquidity
                        </h2>
                        <p class="sm:text-2xl mt-2 text-xl text-left border-0 border-gray-200">
                            To your token from TokenGen
                        </p>
                        <div
                            class="sm:mt-10 sm:gap-6 lg:mt-12 lg:gap-8 grid gap-4 mt-8 leading-7 border-0 border-gray-200"
                        >
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full"
                                >
                                    <div class="box-border m-0 text-xl text-white border-solid">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-6 h-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3
                                        class="sm:text-xl md:text-2xl box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid"
                                    >
                                        Liquidity on Pancakeswap
                                    </h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        When adding liquidity to your token, you're making your token exchangeable on
                                        Pancakeswap.
                                    </p>
                                </div>
                            </div>
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full"
                                >
                                    <div class="box-border m-0 text-xl text-white border-solid">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-6 h-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3
                                        class="sm:text-xl md:text-2xl box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid"
                                    >
                                        Locked liquidity
                                    </h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        To prove to your community that your token is legit and fair, a lot of projects
                                        decides to lock their liquidity.
                                    </p>
                                </div>
                            </div>
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full"
                                >
                                    <div class="box-border m-0 text-xl text-white border-solid">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-6 h-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3
                                        class="sm:text-xl md:text-2xl box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid"
                                    >
                                        Proof of Liquidity
                                    </h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        A lot of sites, such as PooCoins, provides a proof of liquidity. This means
                                        anyone can see how much liquidity there is in a certain token - Transparency is
                                        key for this matter.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="lg:w-1/2 w-full h-full overflow-hidden leading-7 text-gray-900 border-0 border-gray-200 rounded-lg shadow-lg"
                    >
                        <img
                            src="https://i.imgur.com/0PC1LYd.png"
                            class="object-cover w-full h-full"
                        />
                    </div>
                </div>
            </div>
        </section>

        <!-- Section 2 -->
        <section class="bg-blue-50 py py-8 border-t border-b border-blue-100">
            <div class="container mx-auto">
                <div
                    class="lg:flex-row lg:text-left flex flex-col items-center content-center justify-center text-center"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        id="f1a3512c-954b-43cd-b4a7-b2a657bfa049"
                        data-name="Layer 1"
                        width="128"
                        height="128"
                        viewBox="0 0 1135.18 814.22"
                        class="injected-svg modal__media modal__lg_media"
                        data-src="https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/Outer_space_drqu.svg"
                    >
                        <defs>
                            <linearGradient
                                id="a846f69c-c3f1-49f3-adee-5a4e8443ee4e-622"
                                x1="277.46"
                                y1="697.11"
                                x2="277.46"
                                y2="669.52"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop offset="0" stop-color="gray" stop-opacity="0.25"></stop>
                                <stop offset="0.54" stop-color="gray" stop-opacity="0.12"></stop>
                                <stop offset="1" stop-color="gray" stop-opacity="0.1"></stop>
                            </linearGradient>
                            <linearGradient
                                id="b1395abd-e294-4466-9af9-40f9f1f4ad0f-623"
                                x1="748.15"
                                y1="567.16"
                                x2="748.15"
                                y2="544.13"
                                xlink:href="#a846f69c-c3f1-49f3-adee-5a4e8443ee4e-622"
                            ></linearGradient>
                            <linearGradient
                                id="a8b11853-451e-4181-8600-fa718044326b-624"
                                x1="556.93"
                                y1="707.16"
                                x2="556.93"
                                y2="42.89"
                                xlink:href="#a846f69c-c3f1-49f3-adee-5a4e8443ee4e-622"
                            ></linearGradient>
                            <linearGradient
                                id="f1ea05b4-3102-498a-ab85-05d222a08540-625"
                                x1="456.95"
                                y1="628.14"
                                x2="456.95"
                                y2="692.54"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop offset="0" stop-color="#e0e0e0"></stop>
                                <stop offset="0.31" stop-color="#fccc63"></stop>
                                <stop offset="0.77" stop-color="#f55f44"></stop>
                            </linearGradient>
                            <linearGradient
                                id="a65a9945-4cea-45de-8969-2abf9ed9c602-626"
                                x1="534.05"
                                y1="628.14"
                                x2="534.05"
                                y2="692.54"
                                xlink:href="#f1ea05b4-3102-498a-ab85-05d222a08540-625"
                            ></linearGradient>
                            <linearGradient
                                id="e83da883-60d3-4fc8-a1fc-2d21e84cc652-627"
                                x1="582.35"
                                y1="628.14"
                                x2="582.35"
                                y2="692.54"
                                xlink:href="#f1ea05b4-3102-498a-ab85-05d222a08540-625"
                            ></linearGradient>
                            <linearGradient
                                id="e7319a41-e0d4-4be9-9de6-9ff87ed2cc66-628"
                                x1="660.3"
                                y1="628.14"
                                x2="660.3"
                                y2="692.54"
                                xlink:href="#f1ea05b4-3102-498a-ab85-05d222a08540-625"
                            ></linearGradient>
                            <linearGradient
                                id="a8b1f8cd-6e4b-4699-9cd1-501c51b12a87-629"
                                x1="766.05"
                                y1="409.23"
                                x2="766.05"
                                y2="340.57"
                                xlink:href="#a846f69c-c3f1-49f3-adee-5a4e8443ee4e-622"
                            ></linearGradient>
                            <linearGradient
                                id="ef97273a-5cfd-4109-a624-feeb24fdeadc-630"
                                x1="331.05"
                                y1="577.85"
                                x2="331.05"
                                y2="529.94"
                                xlink:href="#a846f69c-c3f1-49f3-adee-5a4e8443ee4e-622"
                            ></linearGradient>
                        </defs>
                        <title>Outer space</title>
                        <path
                            d="M1023.41,751.25a139.92,139.92,0,0,1-27.7-.37c-101.12-11.18-238.82-8-351.07,61.55C489.4,908.6,246.11,826.27,138.79,781.48c-82-34.21-124.57-118-99-196.65.36-1.11.73-2.22,1.12-3.33C57,534.76,88.76,498.18,128.09,478.79c34.28-16.9,84.33-52.19,106.6-120,9.2-28,21.82-54.34,38.21-77.3C316.79,220,408.67,128.43,566.69,129a254.66,254.66,0,0,1,81.41,13.78c57.73,19.67,109.87,59.66,149.63,115.1,25.37,35.36,63.39,68.23,117.82,70.91,75.54,3.74,147.63,38.91,201.71,101.5,1.56,1.8,3.1,3.61,4.64,5.44C1218.19,550.44,1151.31,740.07,1023.41,751.25Z"
                            transform="translate(-32.41 -42.89)"
                            fill="#6c63ff"
                        ></path>
                        <g opacity="0.7">
                            <circle
                                cx="277.46"
                                cy="683.31"
                                r="13.8"
                                fill="url(#a846f69c-c3f1-49f3-adee-5a4e8443ee4e-622)"
                            ></circle>
                        </g>
                        <circle cx="277.46" cy="683.31" r="13.18" opacity="0.2"></circle>
                        <circle cx="203.11" cy="414.14" r="5.1" fill="#fff"></circle>
                        <circle cx="567.11" cy="710.14" r="5.1" fill="#fff"></circle>
                        <circle cx="352.11" cy="736.14" r="2.85" fill="#fff"></circle>
                        <circle cx="983.11" cy="598.14" r="2.85" fill="#fff"></circle>
                        <circle cx="820.11" cy="342.14" r="2.85" fill="#fff"></circle>
                        <circle cx="1051.11" cy="467.14" r="2.85" fill="#fff"></circle>
                        <circle cx="293.11" cy="247.14" r="5.1" fill="#fff"></circle>
                        <polygon
                            points="152.94 592.21 148.4 592.21 148.4 587.67 146.55 587.67 146.55 592.21 142.01 592.21 142.01 594.06 146.55 594.06 146.55 598.59 148.4 598.59 148.4 594.06 152.94 594.06 152.94 592.21"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="266.94 590.21 262.4 590.21 262.4 585.67 260.55 585.67 260.55 590.21 256.01 590.21 256.01 592.06 260.55 592.06 260.55 596.59 262.4 596.59 262.4 592.06 266.94 592.06 266.94 590.21"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="429.94 682.21 425.4 682.21 425.4 677.67 423.55 677.67 423.55 682.21 419.01 682.21 419.01 684.06 423.55 684.06 423.55 688.59 425.4 688.59 425.4 684.06 429.94 684.06 429.94 682.21"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="656.97 130.71 654.9 130.71 654.9 128.64 654.06 128.64 654.06 130.71 651.99 130.71 651.99 131.55 654.06 131.55 654.06 133.62 654.9 133.62 654.9 131.55 656.97 131.55 656.97 130.71"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="951.97 320.71 949.9 320.71 949.9 318.64 949.06 318.64 949.06 320.71 946.99 320.71 946.99 321.55 949.06 321.55 949.06 323.62 949.9 323.62 949.9 321.55 951.97 321.55 951.97 320.71"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="777.97 444.71 775.9 444.71 775.9 442.64 775.06 442.64 775.06 444.71 772.99 444.71 772.99 445.55 775.06 445.55 775.06 447.62 775.9 447.62 775.9 445.55 777.97 445.55 777.97 444.71"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="69.97 522.71 67.9 522.71 67.9 520.64 67.06 520.64 67.06 522.71 64.99 522.71 64.99 523.55 67.06 523.55 67.06 525.62 67.9 525.62 67.9 523.55 69.97 523.55 69.97 522.71"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="154.97 692.71 152.9 692.71 152.9 690.64 152.06 690.64 152.06 692.71 149.99 692.71 149.99 693.55 152.06 693.55 152.06 695.62 152.9 695.62 152.9 693.55 154.97 693.55 154.97 692.71"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="421.97 155.71 419.9 155.71 419.9 153.64 419.06 153.64 419.06 155.71 416.99 155.71 416.99 156.55 419.06 156.55 419.06 158.62 419.9 158.62 419.9 156.55 421.97 156.55 421.97 155.71"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="295.97 454.71 293.9 454.71 293.9 452.64 293.06 452.64 293.06 454.71 290.99 454.71 290.99 455.55 293.06 455.55 293.06 457.62 293.9 457.62 293.9 455.55 295.97 455.55 295.97 454.71"
                            fill="#fff"
                        ></polygon>
                        <polygon
                            points="322.97 368.71 320.9 368.71 320.9 366.64 320.06 366.64 320.06 368.71 317.99 368.71 317.99 369.55 320.06 369.55 320.06 371.62 320.9 371.62 320.9 369.55 322.97 369.55 322.97 368.71"
                            fill="#fff"
                        ></polygon>
                        <g opacity="0.7">
                            <circle
                                cx="748.15"
                                cy="555.65"
                                r="11.52"
                                fill="url(#b1395abd-e294-4466-9af9-40f9f1f4ad0f-623)"
                            ></circle>
                        </g>
                        <circle cx="748.15" cy="555.65" r="10.67" opacity="0.2"></circle>
                        <circle cx="776.88" cy="630.76" r="11.93" opacity="0.2"></circle>
                        <g opacity="0.7">
                            <path
                                d="M702.38,349.92a154.77,154.77,0,0,0-17.5-72c-5.49-10.44-10.5-16.92-14.36-20.91a10.25,10.25,0,0,0-11.74-2.26,10.64,10.64,0,0,0-4.11,2.65c-5.21,5.4-9.92,12.54-14,20.13a155.43,155.43,0,0,0-18.37,74v15.48h-9.93V187c0-37.72-8.44-74.93-25-107.93-7.84-15.64-15-25.36-20.49-31.33a14.2,14.2,0,0,0-21.35,0c-7.44,8.09-14.16,18.79-20,30.16-17.39,33.72-26.22,72-26.22,110.82V366.94h-8.58V350.71a155.39,155.39,0,0,0-18.37-73.95c-4.11-7.59-8.82-14.73-14-20.13a10.27,10.27,0,0,0-12-2.18l.36.19a10.76,10.76,0,0,0-3.35,2.34c-3.85,4-8.86,10.47-14.35,20.91a154.77,154.77,0,0,0-17.5,72v269h30.64L438.78,641v66.13h25.06V641l-3.35-22.12h30.2v-245h8.58V617.82h24.47L520.23,641v66.13h25.06V641l-3.51-23.21h33L571.25,641v66.13h25.06V641l-3.51-23.21h19.54V373.91h9.93V619.28h8.05v-.37h26.62L653.6,641v66.13h25.06V641l-3.35-22.12h27.07Z"
                                transform="translate(-32.41 -42.89)"
                                fill="url(#a8b11853-451e-4181-8600-fa718044326b-624)"
                            ></path>
                        </g>
                        <polygon
                            points="436.4 585.25 412.67 585.25 416.91 556.44 432.16 556.44 436.4 585.25"
                            fill="#535461"
                        ></polygon>
                        <g opacity="0.1">
                            <polygon
                                points="415.73 567.88 433.34 567.88 432.16 559.83 416.91 559.83 415.73 567.88"
                            ></polygon>
                        </g>
                        <polygon
                            points="639.75 585.25 616.03 585.25 620.26 556.44 635.52 556.44 639.75 585.25"
                            fill="#535461"
                        ></polygon>
                        <g opacity="0.1">
                            <polygon
                                points="619.08 567.88 636.7 567.88 635.52 559.83 620.26 559.83 619.08 567.88"
                            ></polygon>
                        </g>
                        <polygon
                            points="513.5 585.25 489.78 585.25 494.01 556.44 509.27 556.44 513.5 585.25"
                            fill="#535461"
                        ></polygon>
                        <g opacity="0.1">
                            <polygon
                                points="492.83 567.88 510.45 567.88 509.27 559.83 494.01 559.83 492.83 567.88"
                            ></polygon>
                        </g>
                        <polygon
                            points="561.8 585.25 538.08 585.25 542.31 556.44 557.56 556.44 561.8 585.25"
                            fill="#535461"
                        ></polygon>
                        <g opacity="0.1">
                            <polygon
                                points="541.13 567.88 558.75 567.88 557.56 559.83 542.31 559.83 541.13 567.88"
                            ></polygon>
                        </g>
                        <rect x="436.4" y="318.35" width="169.46" height="6.78" fill="#535461"></rect>
                        <path
                            d="M664.46,254.16a9.55,9.55,0,0,0-14.16,0c-4.93,5.26-9.39,12.21-13.28,19.6a154.77,154.77,0,0,0-17.39,72V606.6h75V344.66a154.22,154.22,0,0,0-16.57-70.14C672.85,264.36,668.11,258,664.46,254.16Z"
                            transform="translate(-32.41 -42.89)"
                            fill="#e0e0e0"
                        ></path>
                        <path
                            d="M449.39,254.16a9.55,9.55,0,0,1,14.16,0c4.94,5.26,9.39,12.21,13.29,19.6a154.89,154.89,0,0,1,17.39,72V606.6h-75V344.66a154.22,154.22,0,0,1,16.57-70.14C441,264.36,445.74,258,449.39,254.16Z"
                            transform="translate(-32.41 -42.89)"
                            fill="#e0e0e0"
                        ></path>
                        <polygon
                            points="387.25 307.33 405.05 307.42 405.05 329.36 387.25 329.36 387.25 307.33"
                            fill="#535461"
                        ></polygon>
                        <polygon
                            points="444.02 307.33 461.82 307.42 461.82 329.36 444.02 329.36 444.02 307.33"
                            fill="#535461"
                        ></polygon>
                        <polygon
                            points="587.22 307.33 605.01 307.42 605.01 329.36 587.22 329.36 587.22 307.33"
                            fill="#535461"
                        ></polygon>
                        <polygon
                            points="643.99 307.33 661.78 307.42 661.78 329.36 643.99 329.36 643.99 307.33"
                            fill="#535461"
                        ></polygon>
                        <g opacity="0.1">
                            <path
                                d="M476.84,273.42c-3.9-7.39-8.35-14.34-13.29-19.6a9.51,9.51,0,0,0-11.33-2.13,10.11,10.11,0,0,1,2.86,2.13c4.93,5.26,9.39,12.21,13.28,19.6a154.75,154.75,0,0,1,17.39,72V606.24h8.48V345.43A154.88,154.88,0,0,0,476.84,273.42Z"
                                transform="translate(-32.41 -42.89)"
                            ></path>
                        </g>
                        <g opacity="0.1">
                            <path
                                d="M643.8,274.14c3.89-7.39,8.35-14.34,13.28-19.6a9.92,9.92,0,0,1,3.28-2.34,9.54,9.54,0,0,0-10.9,2.34c-4.94,5.26-9.4,12.21-13.29,19.6a154.89,154.89,0,0,0-17.39,72V607h7.63V346.16A154.77,154.77,0,0,1,643.8,274.14Z"
                                transform="translate(-32.41 -42.89)"
                            ></path>
                        </g>
                        <path
                            d="M457.27,692.54h-.65A11.54,11.54,0,0,1,445.08,681V628.14h23.73V681A11.54,11.54,0,0,1,457.27,692.54Z"
                            transform="translate(-32.41 -42.89)"
                            fill="url(#f1ea05b4-3102-498a-ab85-05d222a08540-625)"
                        ></path>
                        <path
                            d="M534.38,692.54h-.66A11.54,11.54,0,0,1,522.19,681V628.14h23.72V681A11.54,11.54,0,0,1,534.38,692.54Z"
                            transform="translate(-32.41 -42.89)"
                            fill="url(#a65a9945-4cea-45de-8969-2abf9ed9c602-626)"
                        ></path>
                        <path
                            d="M582.67,692.54H582A11.54,11.54,0,0,1,570.49,681V628.14h23.72V681A11.54,11.54,0,0,1,582.67,692.54Z"
                            transform="translate(-32.41 -42.89)"
                            fill="url(#e83da883-60d3-4fc8-a1fc-2d21e84cc652-627)"
                        ></path>
                        <path
                            d="M660.63,692.54H660A11.54,11.54,0,0,1,648.44,681V628.14h23.72V681A11.54,11.54,0,0,1,660.63,692.54Z"
                            transform="translate(-32.41 -42.89)"
                            fill="url(#e7319a41-e0d4-4be9-9de6-9ff87ed2cc66-628)"
                        ></path>
                        <path
                            d="M566.34,49.64a13.2,13.2,0,0,0-20.21,0c-7,7.88-13.4,18.29-19,29.37-16.46,32.84-24.82,70.12-24.82,107.92V605.54h107V185.25c0-36.73-8-73-23.64-105.1C578.32,64.92,571.55,55.45,566.34,49.64Z"
                            transform="translate(-32.41 -42.89)"
                            fill="#eee"
                        ></path>
                        <rect x="494.79" y="53.25" width="55.84" height="25.59" rx="11" fill="#535461"></rect>
                        <g opacity="0.1">
                            <rect x="470.48" y="158.35" width="105.91" height="4.11"></rect>
                        </g>
                        <path
                            d="M793.45,354.22h0a34.62,34.62,0,0,0-10.22-9h0a34.33,34.33,0,1,0-17.17,64.07c.73,0,1.46,0,2.17-.08a34.32,34.32,0,0,0,25.23-54.93Z"
                            transform="translate(-32.41 -42.89)"
                            fill="url(#a8b1f8cd-6e4b-4699-9cd1-501c51b12a87-629)"
                        ></path>
                        <path
                            d="M798.5,374.9a32.45,32.45,0,0,1-30.4,32.38c-.67,0-1.36.07-2.05.07a32.46,32.46,0,1,1,16.23-60.56h0a32.59,32.59,0,0,1,9.66,8.56h0A32.3,32.3,0,0,1,798.5,374.9Z"
                            transform="translate(-32.41 -42.89)"
                            opacity="0.2"
                        ></path>
                        <path
                            d="M758.06,354.31a34.88,34.88,0,0,0-4.84,2.55,7.61,7.61,0,0,0-2.59,2.32,3,3,0,0,0-.06,3.31c.79,1.08,2.31,1.24,3.65,1.32a9.34,9.34,0,0,0,4.18-.42,9,9,0,0,0,2.44-1.77l2.79-2.53c1-.88,2-2.09,1.61-3.33a3,3,0,0,0-2.37-1.6,9.92,9.92,0,0,0-7,1.23"
                            transform="translate(-32.41 -42.89)"
                            opacity="0.1"
                        ></path>
                        <g opacity="0.1">
                            <circle cx="714" cy="344.82" r="1.71"></circle>
                        </g>
                        <g opacity="0.1">
                            <circle cx="748.72" cy="334" r="6.26"></circle>
                        </g>
                        <g opacity="0.1">
                            <path
                                d="M792,355.35h0a8.54,8.54,0,1,1-9.67-8.56h0A32.59,32.59,0,0,1,792,355.35Z"
                                transform="translate(-32.41 -42.89)"
                            ></path>
                        </g>
                        <g opacity="0.1">
                            <path
                                d="M792.58,393.58a32.37,32.37,0,0,1-24.48,13.7c0-.45-.06-.9-.06-1.36a15.38,15.38,0,0,1,24.54-12.34Z"
                                transform="translate(-32.41 -42.89)"
                            ></path>
                        </g>
                        <path
                            d="M350.17,539.47h0a24.15,24.15,0,0,0-7.14-6.32h0a24,24,0,1,0-12,44.7c.51,0,1,0,1.52-.05a24,24,0,0,0,17.6-38.33Z"
                            transform="translate(-32.41 -42.89)"
                            fill="url(#ef97273a-5cfd-4109-a624-feeb24fdeadc-630)"
                        ></path>
                        <path
                            d="M353.69,553.9a22.64,22.64,0,0,1-21.21,22.59c-.47,0-1,0-1.43,0a22.64,22.64,0,1,1,11.32-42.25h0a22.67,22.67,0,0,1,6.74,6h0A22.52,22.52,0,0,1,353.69,553.9Z"
                            transform="translate(-32.41 -42.89)"
                            opacity="0.2"
                        ></path>
                        <path
                            d="M325.47,539.54a23.5,23.5,0,0,0-3.37,1.77,5.54,5.54,0,0,0-1.81,1.62,2.13,2.13,0,0,0,0,2.31,3.23,3.23,0,0,0,2.54.92,6.51,6.51,0,0,0,2.92-.29,6.29,6.29,0,0,0,1.71-1.24l1.94-1.76c.68-.62,1.42-1.46,1.12-2.32a2,2,0,0,0-1.65-1.12,6.87,6.87,0,0,0-4.87.86"
                            transform="translate(-32.41 -42.89)"
                            opacity="0.1"
                        ></path>
                        <g opacity="0.1">
                            <circle cx="284.93" cy="519.94" r="1.19"></circle>
                        </g>
                        <g opacity="0.1">
                            <circle cx="309.16" cy="512.4" r="4.37"></circle>
                        </g>
                        <g opacity="0.1">
                            <path
                                d="M349.12,540.26h0a6,6,0,1,1-6.75-6h0A22.67,22.67,0,0,1,349.12,540.26Z"
                                transform="translate(-32.41 -42.89)"
                            ></path>
                        </g>
                        <g opacity="0.1">
                            <path
                                d="M349.56,566.93a22.59,22.59,0,0,1-17.08,9.56c0-.31,0-.63,0-1a10.73,10.73,0,0,1,17.12-8.61Z"
                                transform="translate(-32.41 -42.89)"
                            ></path>
                        </g>
                        <polyline points="837.69 539.42 859.49 551.02 842.47 570.06" fill="none"></polyline>
                        <circle cx="954.9" cy="428.23" r="1.84" fill="#fff"></circle>
                    </svg>

                    <div class="flex flex-col content-center px-4 mx-4 my-6">
                        <h4 class="mb-md-0 pb-0 mb-0 text-lg font-semibold">Haven't created your token yet?</h4>
                        <p class="mb-0 text-gray-700">Create your token with us today. We also have a FREE token!</p>
                    </div>
                    <main-button :link="{ name: 'create' }">Create token</main-button>
                </div>
            </div>
        </section>

        <!-- Section 3 -->
        <section class="sm:py-20 w-full py-12 bg-white">
            <div class="xl:flex-row max-w-7xl flex flex-col items-center justify-center mx-auto">
                <div
                    class="xl:max-w-none xl:w-2/5 relative flex flex-col items-start justify-center w-full h-full max-w-2xl px-6 mx-auto"
                >
                    <h2 class="sm:w-4/5 sm:text-6xl text-3 text-3xl font-normal leading-none text-gray-900">
                        Sites that we recommend
                    </h2>
                    <div class="w-20 h-1 mt-3 ml-1 bg-indigo-600 rounded-full"></div>

                    <svg
                        class="sm:block xl:mr-5 xl:-mt-24 absolute top-0 right-0 hidden w-auto h-24 mt-20 text-indigo-600 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 180 74"
                    >
                        <defs></defs>
                        <g fill-rule="nonzero">
                            <path
                                d="M43.13 71.58c.29-.025.58-.025.87 0 .3 0 .71-.14 1-.17.54 0 1.08-.19 1.62-.23l2.3-.17 4.65-.25 6.47-.26h.68c3-.15 6-.3 9-.53 1.68-.14 5.83-.49 6.21-.52.63 0 4.36-.53 5-.63.25 0 1.95-.35 3.35-.58-.09-1-.55-1.77-.62-2.71-4.33.66-8.7 1.18-13 1.51-5.34.4-10.71.66-16.08 1l-7 .39c-1.4 0-2.82-.31-4.17-.26a2 2 0 00-1 .31c-.3.21-.4.16-.5.3a1.18 1.18 0 00-.12.81c.06.84.66 2.11 1.34 1.99zM106.33 62.04l.5-.23c.31-.13.72-.33 1.16-.56.88-.46 1.94-1 2.59-1.38l.56-.31a31.87 31.87 0 002.81-1.74 48.77 48.77 0 0014-13.59 38.22 38.22 0 004.34-8.87 28.9 28.9 0 001.51-9.86 31.56 31.56 0 00-3.3-13.46 23.9 23.9 0 00-3.62-5.22 20.47 20.47 0 00-2.38-2.22c-.42-.34-.89-.63-1.33-.94a10.88 10.88 0 00-1.38-.85A18.21 18.21 0 00109.16.92c-.5.11-1 .22-1.45.33-.45.11-.88.31-1.3.47-.42.16-.86.3-1.27.49-.41.19-.8.41-1.21.61A27.71 27.71 0 0098.5 6.5l-1.51 1.42-1.59 1.84c-.51.66-1 1.36-1.44 2-.44.64-.75 1.28-1 1.78a22.66 22.66 0 00-2 5 23.44 23.44 0 00-.82 5.31 26.71 26.71 0 00.72 7c.22 1.16.65 2.26 1 3.38a33.94 33.94 0 001.41 3.21 36.93 36.93 0 008.44 10.95 47.5 47.5 0 005.77 4.43 35.5 35.5 0 0010.02 4.59 86.41 86.41 0 0010 2.09 84.59 84.59 0 0018.2.51c4.8-.31 9.33-.8 13.8-1.39 2.25-.3 4.49-.63 6.76-1l3.43-.59.83-.11a15.59 15.59 0 001.98-.25 46 46 0 014.66-.82c.69-.12 2.24-.87 2.34-1.35.06-.28-.19-.56-.15-.85.09-.65-1.16-1.47-2.06-1.25-.9.22-1.89.51-2.84.73-.95.22-2 .37-3 .62a9.82 9.82 0 00-1.16.38c-.19.06-.39.13-.58.18l-.29.08-.69.12c-3.55.62-7 1.3-10.81 1.68-5.54.53-11.42 1.31-17.15 1.37a73.61 73.61 0 01-18.84-1.81 41.54 41.54 0 01-16.81-8.06 37.32 37.32 0 01-7.9-8.78 27.1 27.1 0 01-4.12-10.8C91.25 17.6 98.76 6.5 108.89 3.76a15.83 15.83 0 0114.56 3.4 23.24 23.24 0 017.36 13.74 28.32 28.32 0 01.29 8 28.05 28.05 0 01-2.06 7.7 37.16 37.16 0 01-5 8.63 39.08 39.08 0 01-7 7l-.87.66-.14.11c-1.69 1.29-3.61 2.56-5.55 3.75a54.34 54.34 0 01-12 5.4c-.42 1-1 2.35-.6 3.17 1.18-.35 2.25-.69 3.52-1.19.8-.28 1.61-.63 2.44-1 .83-.37 1.66-.72 2.49-1.09z"
                            ></path>
                            <path
                                d="M46.93 71a8.72 8.72 0 011.16 0c.51 0 1.48.05 2 0l8.52-.5c8.84-.54 17.78-1 26.66-2.45 2.33-.38 4.67-.8 7-1.29a56.65 56.65 0 0010.45-3.26 85.2 85.2 0 009.11-4.57 48.44 48.44 0 0014-12c.86-1 1.57-2.14 2.33-3.2s1.34-2.12 1.89-3.23a35.91 35.91 0 002.81-7.11 31.08 31.08 0 00.4-12.78 6.21 6.21 0 01-1.89 2.64 25.44 25.44 0 01-1 9.32l-.09.26a21.31 21.31 0 01-.69 2 41.94 41.94 0 01-3.72 7.43 41.78 41.78 0 01-5.3 6.63 52.72 52.72 0 01-15.45 10.61 73.71 73.71 0 01-18.17 5.41 207.23 207.23 0 01-24.09 2.59l-15.92.87a4.07 4.07 0 01-.01 2.63zM21.1 71.79a1.43 1.43 0 01-.27-1.49 2.72 2.72 0 011.81-1.54c1-.14 2.13.44 3.2.44 1.47 0 2.94-.27 4.42-.39 1-.08 1.92 0 2.86-.15a17 17 0 012.57-.11 5.7 5.7 0 001.17 0 3 3 0 011.12-.16c1 .18 1.3 2.22.71 2.91-.45.53-1.56.36-2.18.36h-2.67c-2.13.13-4.28 0-6.41.1-.91 0-1.8.24-2.7.35-.9.11-1.7.15-2.56.2a1.31 1.31 0 01-1.07-.52zM7.5 71.7a3.09 3.09 0 010-1 1.26 1.26 0 01.4-.74 2.18 2.18 0 012.16-.49 9.2 9.2 0 002.87 0 9.22 9.22 0 013.1 0 2 2 0 011.17.72c.46.6.61 1.35-.14 1.8a5.18 5.18 0 01-2.91.44c-1.34-.13-2.75.53-4.15.76a2 2 0 01-1.34-.22A2.49 2.49 0 017.5 71.7zM.01 71.57c.082-.29.2-.569.35-.83a1.91 1.91 0 013.27-.25c.54.63.61 2.26-.16 2.72a4.27 4.27 0 01-1.32.44c-1.12.1-2.05-.23-2.14-2.08z"
                            ></path>
                        </g>
                    </svg>
                </div>
                <div
                    class="xl:w-3/5 rounded-xl box-content relative flex items-center w-full h-auto max-w-2xl py-5 mx-auto overflow-hidden"
                >
                    <!-- Slide Page 1 -->
                    <div class="xl:pl-6 h-94 relative flex px-6 space-x-6 transition duration-500 ease-out transform">
                        <!-- story 1 -->
                        <div class="flex flex-col flex-shrink-0 w-1/2 overflow-hidden rounded-lg shadow-lg">
                            <div class="flex-shrink-0">
                                <a href="https://pancakeswap.finance/">
                                    <img
                                        class="sm:h-48 object-cover w-full h-32"
                                        src="https://i.imgur.com/0PC1LYd.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div class="flex flex-col justify-between flex-1 p-6 bg-white">
                                <div class="flex-1">
                                    <p
                                        class="inline-block py-1 pl-10 pr-4 mb-2 -ml-10 text-xs font-medium leading-5 text-white transform -translate-y-2 bg-indigo-600 rounded"
                                    >
                                        <a href="https://pancakeswap.finance/" class="hover:underline" rel="category"
                                            >Exchange</a
                                        >
                                    </p>
                                    <a href="https://pancakeswap.finance/" class="block">
                                        <span
                                            class="sm:text-xl mt-2 text-base font-semibold leading-tight leading-7 text-gray-900"
                                            >Pancakeswap Exchange</span
                                        >
                                        <span class="sm:text-sm block mt-3 text-xs leading-6 text-gray-500"
                                            >One of the biggest BSC Exchanges available. This is the recommended
                                            exchange to use by tokengen.
                                        </span>
                                    </a>
                                </div>

                            </div>
                        </div>

                        <!-- story 2 -->
                        <div class="flex flex-col flex-shrink-0 w-1/2 overflow-hidden rounded-lg shadow-lg">
                            <div class="flex-shrink-0">
                                <a href="https://poocoin.app/">
                                    <img
                                        class="sm:h-48 object-cover w-full h-32"
                                        src="https://cdn2.benzinga.com/files/imagecache/og_image_social_share_1200x630/images/story/2012/poo.png?width=720"
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div class="flex flex-col justify-between flex-1 p-6 bg-white">
                                <div class="flex-1">
                                    <p
                                        class="inline-block py-1 pl-10 pr-4 mb-2 -ml-10 text-xs font-medium leading-5 text-white transform -translate-y-2 bg-indigo-600 rounded"
                                    >
                                        <a href="https://poocoin.app/" class="hover:underline" rel="category">Charts</a>
                                    </p>
                                    <a href="https://poocoin.app/" class="block">
                                        <span
                                            class="sm:text-xl mt-2 text-base font-semibold leading-tight leading-7 text-gray-900"
                                            >PooCoin Charts</span
                                        >
                                        <span class="sm:text-sm block mt-3 text-xs leading-6 text-gray-500"
                                            >PooCoin is one of the best and fastest chart sites when it comes to BSC
                                            Tokens. They also provide liquidity information.</span
                                        >
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Section 4 -->
        <section class="py-20 bg-white">
            <div class="max-w-7xl xl:px-12 flex flex-col px-8 mx-auto space-y-12">
                <div class="relative">
                    <h2 class="sm:text-4xl md:text-5xl w-full text-3xl font-bold text-center">Adding liquidity</h2>
                    <p class="intro sm:max-w-3xl w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700">
                        When adding liquidity to your token, it's important to remember that anyone will be able to use
                        PancakeSwap to buy/exchange other currencies to your token.
                    </p>
                </div>
                <div class="animated fadeIn sm:flex-row flex flex-col mb-8">
                    <div class="sm:w-1/2 md:w-5/12 sm:order-last flex items-center mb-8">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/7ntzDff.png" alt="" />
                    </div>
                    <div class="md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16 flex flex-col justify-center mt-5 mb-8">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            ADDING LIQUIDITY
                        </p>
                        <h3 class="sm:text-left md:text-4xl mt-2 text-2xl">PancakeSwap Liquidity</h3>
                        <p class="text md:text-left mt-5 text-lg text-gray-700">
                            To add liquidity to your token head over to PancakeSwap Liquidity. From here you'll see the
                            "add liquidity" button. Click this button and you'll see a new page. It should show 2 boxes,
                            one is BNB and the other one is empty. Click on "Select a currency" on the bottom one and
                            paste in your contract code for your token. When you've added your token you'll see a lot of
                            information comming up regarding your liquidity. From here you want to type how much BNB you
                            want to throw into the liquidity pool. Example: If you put 1 BNB into a liquidity pool and
                            you add 100.000 tokens from your wallet, 1 of your tokens will be worth 0.00001 BNB. This
                            means people will be able to buy 1 of your tokens for the total of 0.00001 BNB + gas fees.
                            Last press the "Approve TokenName" and confirm the transaction in your web3 wallet.
                        </p>
                    </div>
                </div>
                <div class="animated fadeIn sm:flex-row flex flex-col mb-8">
                    <div class="sm:w-1/2 md:w-5/12 flex items-center mb-8">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/Zcm263f.png" alt="" />
                    </div>
                    <div class="md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16 flex flex-col justify-center mt-5 mb-8">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            REMOVING LIQUIDITY
                        </p>
                        <h3 class="sm:text-left md:text-4xl mt-2 text-2xl">Remove liquidity from PancakeSwap</h3>
                        <p class="text md:text-left mt-5 text-lg text-gray-700">
                            If you want to remove liquidity from your pool, you want to head over to the liquidity part
                            of PancakeSwap once again. From here you'll be able to see what pools you have liquidity in.
                            If you do not see your pool, click the "import pool" and paste in your contract and you'll
                            be able to see it. Once it's visible, click it and you'll see your liquidity in your pools.
                            You now have to ability to remove X% from your pool. Simply select the wished amount to
                            withdraw and confirm it in metamask.
                        </p>
                    </div>
                </div>
                <div class="animated fadeIn sm:flex-row flex flex-col mb-8">
                    <div class="sm:w-1/2 md:w-5/12 sm:order-last flex items-center mb-8">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/WaYEiKE.png" alt="" />
                    </div>
                    <div class="md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16 flex flex-col justify-center mt-5 mb-8">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            Locked liquidity
                        </p>
                        <h3 class="sm:text-left md:text-4xl mt-2 text-2xl">Lock your liquidity</h3>
                        <p class="text md:text-left mt-5 text-lg text-gray-700">
                            Many communities wishes to lock their liquidity. This is done to prove their fairness to the
                            community. There's many reasons to lock liquidity, one of them being RugPulls, which means
                            putting liquidity into your coin, making people buy it and then remove the whole liquidity
                            pool at once. Tokengen does not provide any help to lock your liquidity, but we advice you
                            to take a look at <a href="https://cryptexlock.me/" class="underline">cryptexlock.me</a> for more
                            information about locking liquidity.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import MainButton from '../../components/MainButton';
export default {
    name: 'Features',
    components: { MainButton },
};
</script>

<style scoped></style>
